import { Header } from "./components/Header";
import { HeroArea } from "./components/HeroArea";
import { About } from "./components/About";
import { Services } from "./components/Services";
import { Projects } from "./components/Projects";
import { Activities } from "./components/Activities";
import { Contacts } from "./components/Contacts";
import { Footer } from "./components/Footer";

import "./index.css";

export default function App() {
  return (
    <div className="App">
      <Header />
      <HeroArea />
      <h1 id="about" className="title">
    About
      </h1>

      <p className="description">Work and education</p>
      <About />
      <h1 id="services" className="title">
        Services
      </h1>

      <p className="description">Expertise</p>
      <Services />
      <h1 id="projects" className="title">

        Projects
      </h1>
      <p className="description">For Fun</p>
      <Projects />

      <h1 id="activities" className="title">
      Contacts
      </h1>
      <p className="description">Im available for work</p>
      <Contacts />
      <Footer />
    </div>
  );
}
