export const about = [

  {
    title: "Cisco Network Academy Instructor",
    duration: "September 2018 - Present",
    icon: "/assets/about/cisco_5yearsofservice.png",
    achievment:"I have helped many students reach their goal in and land a job in the network industry",
    achievment2:"Being in charge of the it-program and constantly integrating new ideas"
  },
  {
    title: "Instructor Webdevelopment",
    duration: "Aug 2017 - Sept 2018",
    icon: "/assets/about/code_logos.png",
    achievment:"Teahching javascript frameworks and HTML, CSS",
    achievment2:"Bootstrap och Git"
  },
  {
    title: "Nanyang Technological University, Singapore",
    duration: "Apr 2006 - Sept 2008",
    icon: "/assets/about/NTU_square.png",
    achievment:"Position as a Research Associate",
    achievment2:"Published several academic articles and papers",
  },
{
    title: "Göteborgs University",
    duration: "Aug 2002 - Jan 2004",
    icon: "/assets/about/gu_480px.png",
    achievment:"Master of Science ICT and Education",
  },
  {
    title: "Lund University",
    duration: "Aug 1993 - Sept 1998",
    icon: "/assets/about/lu_logo_512px.png",
    achievment:"Bachelor of Art Philosophy and Political Science",
  },
];
