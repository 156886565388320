export const projects = [
  {
    title: <a href="https://blog.netfence.se" target="_blank"> Technology, art and freedom </a>,
    description: "My selfhosted blog with Ghost",
    image: "/assets/projects/plato_aristoteles.png",
  },
  {
    title: "Creative Coding",
    description: "Creative javascript with Canvas",
    image: "/assets/projects/creative-transparent.png",
  },
];
