import styles from "./HeroArea.module.css";

export const HeroArea = () => {
  return (
    <section className={styles.wrapper}>
      <img className={styles.profile} src="/assets/profile.png" alt="profile" />
      <div className={styles.socials}>

      <a href="https://twitter.com/netfenceAB" target="_blank">
        <img
          src="/assets/socials/twitter.png"
          alt="social-icon"
          className={styles.icon}
        /></a>
       <a href ="https://www.linkedin.com/in/hanslossmann/" target="_blank">
          <img
         src="/assets/socials/linkedin.png"
          alt="social-icon"
          className={styles.icon}
        /></a>
      <a href="https://github.com/lundix" target="_blank">
        <img
          src="/assets/socials/github.png"
          alt="social-icon"
          className={styles.icon}
        /></a>
      </div>
      <h1 className="title"> Hello, my name is Hans !</h1>

      <p className="description">
      I am an entrepreneurial person who run my own company. I am available both for traditional employment and/or
      assignments as a consultant. I love IT and have a mix of deep skills and more general capabílities within IT. I have taught web development
      framework like javascript (think React and Angular) bootstrap and technologies like HTML and CSS.
      My specialist area is network technology and severmanagement with focus on security (especially Cisco and Linux).
      I also have skills in Docker and Kubernetes and know how to set up a DevOps environment.
      </p>
      <div className={styles.buttonBox}>
        <button
          className={`${styles.button} ${styles.project}`}
          onClick={() => {
            document
              .getElementById("projects")
              .scrollIntoView({ behavior: "smooth" });
          }}
        >
          Projects
        </button>
        <button
          className={`${styles.button} ${styles.hire}`}
          onClick={() => {
            document
              .getElementById("contact")
              .scrollIntoView({ behavior: "smooth" });
          }}
        >
          Contact
        </button>

      </div>
    </section>

  );
};
