export const services = [
  {
    icon: "/assets/services/web.png",
    title: "Design and development",
    description: "React and Angular, CSS, Boostrap, Node.js backend",
  },
  {
    icon: "/assets/services/cisco.png",
    title: "Networking",
    description: "Cisco Networking, Network Security, VLAN, Routing, IP-adressering, VPN, cryptography",
  },
  {
    icon: "/assets/services/linux_white.png",
    title: "Linux",
    description: "Docker, Kubernetes, Nginx, Scriptning",
  },
];
